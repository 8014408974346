/**
 * External Dependencies
 */

 import { WOW } from 'wowjs';

 const wow = new WOW();
 wow.init();
 
 $(() => { 
  if ($('.media__cell .media__bg').length > 0) {
    $('.media__cell .media__bg').each(function(){
      var mediaWidth = $(this).width();
      var mediaHeight = $(this).height();
      if (mediaWidth > mediaHeight) {
        $(this).css({'height': mediaWidth});
      } else {
        $(this).css({'width': mediaHeight});
      }
    });
  }

  if ($('.hero').length > 0) {
    $('.breadcrumbs__container').remove();
  }
  
  $('li.has-submenu').hover(
    function() {
      $(this).addClass('is-hover');
    },
    function() {
      var $this = $(this);
      // Vertraging voordat de klasse wordt verwijderd, zodat je naar het submenu kunt gaan
      setTimeout(function() {
        if (!$this.is(':hover') && !$this.find('ul.submenu').is(':hover')) {
          $this.removeClass('is-hover');
        }
      }, 100); // Vertraagd om tijd te geven om naar het submenu te bewegen
    }
  );

  // Zorg ervoor dat de is-hover klasse actief blijft zolang je over het submenu hovert
  $('ul.submenu').hover(
    function() {
      $(this).closest('li.has-submenu').addClass('is-hover');
    },
    function() {
      var $this = $(this).closest('li.has-submenu');
      // Vertraging voordat de klasse wordt verwijderd
      setTimeout(function() {
        if (!$this.is(':hover') && !$this.find('ul.submenu').is(':hover')) {
          $this.removeClass('is-hover');
        }
      }, 100);
    }
  );
   
 });
 