/**
 * External Dependencies
 */
import 'jquery';
import 'foundation-sites/js/entries/foundation'
import 'foundation-sites/js/entries/foundation-plugins'

$(() => {
  $(document).foundation();
  
  let menu_height = $('header.primary__nav').outerHeight();
  $('body').css('padding-top', menu_height+'px');
  
  $(window).on('resize load', function() {
    let menu_height = $('header.primary__nav').outerHeight();
    $('body').css('padding-top', menu_height+'px');
  });
  
  $('.menu__toggle').on('click', function(event) {
    event.preventDefault();
    $('#side__menu').toggleClass('active');
    $('header.primary__nav .menu__toggle').toggleClass('active');
  });
});
